import React from "react";
import { useEffect, useState  } from "react";
//import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel, CarouselItem } from 'react-bootstrap';
import { getMarcasAnunciadas } from "../../../services/BuscaService";
import { useNavigate } from "react-router-dom";

function Marcas(){

    const [listaDeMarcas, setListaDeMarcas ] = useState([]);


    const navigate = useNavigate();

    const getMarcas = () => {

        getMarcasAnunciadas()
          .then((result) => {
            if (result) {
              setListaDeMarcas(result);
              console.log(result);
            } else {
              alert("Nenhum anúncio encontrado");
            }
          })
          .catch(console.log(""));
    };
    
    useEffect(() => {    
        getMarcas();    
    }, []);    



return (

<div className="container col-12 mb-3 mt-5">
<div>
    <div className="container text-center">
        <div className="row">
            <div className="col-12">
                <Carousel> 
                    <CarouselItem>
                        {listaDeMarcas?.map((marca) => {
                            return (
                                <img src={require(`../../../assets/images/marcas/${marca.logomarca}.png`)} className="img-fluid img-thumbnail" 
                                alt={marca.nomemarca} width={100} />
                            );
                        })}
                    </CarouselItem>
                    {/* 
                    <CarouselItem>
                        <img src={volks} className="img-fluid img-thumbnail" alt="volkswagen" width={100} />
                        <img src={ford} className="img-fluid img-thumbnail" alt="ford" width={100} />
                        <img src={hyundai} className="img-fluid img-thumbnail" alt="hyundai" width={100} />
                        <img src={alfaromeo} className="img-fluid img-thumbnail" alt="alfaromeo" width={100} />
                        <img src={fiat} className="img-fluid img-thumbnail" alt="fiat" width={100} />
                        <img src={gm} className="img-fluid img-thumbnail" alt="gm" width={100} />
                    </CarouselItem>
                    <CarouselItem>
                        <img src={fiat} className="img-fluid img-thumbnail" alt="fiat" width={100} />
                        <img src={toyota} className="img-fluid img-thumbnail" alt="toyota" width={100} />
                        <img src={renault} className="img-fluid img-thumbnail" alt="renault" width={100} />
                        <img src={mitsubish} className="img-fluid img-thumbnail" alt="mitsubish" width={100} />
                        <img src={honda} className="img-fluid img-thumbnail" alt="honda" width={100} />
                    </CarouselItem>  */}
                </Carousel>
            </div>
        </div>
    </div>
</div>

</div>
);
}

export default Marcas;