import http from "../utils/http";

const context = "buscas";

export const getNovidades = () => {
  return http.get(`/${context}/getPosts`).then(result => result.data);
};

export const getAnuncios = (termo) => {
  return http.get(`/${context}/getAnuncios?termo=` + termo).then(result => result.data);
};

export const getAnuncioPorId = (id) => {
  return http.get(`/${context}/getAnuncioPorId/` + id).then(result => result.data);
};

export const getAnunciosByKeywords = (formData) => {
  return http.get(`/${context}/getAnunciosByKeywords?categoria=${formData.categoria}&marca=${formData.marca}&modelo=${formData.modelo}&valor=${formData.valor}&cidade=${formData.cidade}`).then(result => result.data);
};

export const getMarcasAnunciadas = () => {
  return http.get(`/${context}/getMarcasAnunciadas`).then(result => result.data);
};

