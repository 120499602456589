import { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

import CloseIcon from "@mui/icons-material/Close";
import InputLabel from '@mui/material/InputLabel';
import MKInput from "components/MKInput";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { gravaNovoModelo } from "services/Cadastro";  
import { getCaracteristicasModelo } from "services/AnuncioService"

function AnuncioModal({modelo, idTipoVeiculo}) {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);
  const [modeloVM, setModeloVM] = useState({});
  const [descricaoModelo, setDescricaoModelo] = useState("");
  const [portas, setPortas ] = useState(0);
  const [cambio, setCambio ] = useState("");
  const [ combustivel, setCombustivel ] = useState("");
  const [ tipoVeiculo, setTipoVeiculo ] = useState(idTipoVeiculo); //automóvel, ônibus...
  const [ modeloMarca, setModeloMarca ] = useState(modelo); // gol, passat...
  const [ carroceria, setCarroceria ] = useState("");
  const [ listaDeModelosPorMarca , setListaDeModelosPorMarca ] = useState([]); 

  const handleNovoModelo = () => {
    console.log(modeloVM);
    montaNovoModelo();
    gravaNovoModelo(modeloVM)
    .then(dados => {
      //setLoading(false);
      getListaDeCaracteristicasModelos(parseInt(modeloMarca));  
      ListaDeNodelos
    }
    ).catch();
  }

  const getListaDeCaracteristicasModelos = (id) => {
    getCaracteristicasModelo(id)
    .then(dados => {
      if (dados) {
        setListaDeModelosPorMarca(dados);      
      }
      else{
        setListaDeModelosPorMarca("");  
      }
    }
    ).catch();         
  }

  let montaNovoModelo =() => {
    modeloVM.descricao = descricaoModelo;
    modeloVM.idModeloMarca = parseInt(modeloMarca);
    modeloVM.idVeiculo = parseInt(tipoVeiculo);
    modeloVM.idCarroceria = parseInt(carroceria);
    modeloVM.idCambio = parseInt(cambio); 
    modeloVM.idCombustivel = parseInt(combustivel); 
    modeloVM.portas = parseInt(portas);
    return modeloVM;
  }

  return (
    <MKBox component="section" py={1}>
      <Container>
        <Grid container item xs={12} md={12} justifyContent="center" mx="auto">
          <MKButton variant="gradient" color="success" onClick={toggleModal}>
            Novo Modelo
          </MKButton>
        </Grid>
        <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
          <Slide direction="down" in={show} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                <MKTypography variant="h5">Novo Modelo {modelo} {idTipoVeiculo}</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}> 

              <Grid  item xs={12} md={12}>
                <InputLabel>Descrição</InputLabel>
                <MKInput
                  value={descricaoModelo}
                  type="text"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => setDescricaoModelo(e.target.value)}
                />
              </Grid>                  
              <Grid item xs={12} md={12}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>Carroceira</InputLabel>
                <Select value={carroceria} onChange={(e) => setCarroceria(e.target.value)}>
                  <MenuItem value="">... carroceria ...</MenuItem>
                  <MenuItem value="2"> SUV</MenuItem>
                  <MenuItem value="3">Hatch</MenuItem>
                  <MenuItem value="4">Sedan</MenuItem>
                  <MenuItem value="5">Perua</MenuItem>
                  <MenuItem value="6">Cabine Dupla</MenuItem>
                  <MenuItem value="7">Cabine simples</MenuItem>
                  <MenuItem value="8">Cabie estendida</MenuItem>
                </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>Câmbio</InputLabel>
                <Select value={cambio} onChange={(e) => setCambio(e.target.value)}>
                  <MenuItem value="">... câmbio ...</MenuItem>
                  <MenuItem value="1">Manual</MenuItem>
                  <MenuItem value="2">Automático</MenuItem>
                  <MenuItem value="3">Automatizado</MenuItem>
                  <MenuItem value="4">CVT</MenuItem>
                </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel>Combustível</InputLabel>
                <Select value={combustivel} onChange={(e) => setCombustivel(e.target.value)}>
                  <MenuItem value="">... combustível ...</MenuItem>
                  <MenuItem value="1">Gasolina</MenuItem>
                  <MenuItem value="2">Etanol</MenuItem>
                  <MenuItem value="3">Gas</MenuItem>
                  <MenuItem value="4">Diesel</MenuItem>
                  <MenuItem value="5">Flex</MenuItem>
                  <MenuItem value="6">Tetrafuel</MenuItem>
                  <MenuItem value="7">Elétrico</MenuItem>
                </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} mt={1}>
                <InputLabel>Portas</InputLabel>
                <MKInput 
                  type="text" 
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  value={portas}  
                  onChange={(e) => setPortas(e.target.value)}/>
              </Grid> 
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={0.9}>
                <MKButton variant="gradient" color="primary" data-bs-toggle="collapse" data-bs-target="#cadastroModelo" 
                    aria-expanded="false" aria-controls="collapseExample"  onClick={handleNovoModelo}>
                    Gravar
                </MKButton>&nbsp;
                <MKButton variant="gradient" color="danger" onClick={toggleModal}>
                    Fechar
                </MKButton>
            </MKBox>
            </MKBox>
          </Slide>
        </Modal>
      </Container>
    </MKBox>
  );
}

export default AnuncioModal;
