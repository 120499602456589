import { useState } from "react";
//import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import DefaultFooter from "auxiliares/Footers/DefaultFooter";
import Counters from "pages/Dashboard/sections/Counters";
import Information from "pages/Dashboard/sections/Information";
import Novidades from "pages/Dashboard/sections/Novidades";
import Testimonials from "pages/Dashboard/sections/Testimonials";
import Marcas from "pages/Dashboard/sections/Marcas"
import footerRoutes from "footer.routes";
import { useNavigate } from "react-router-dom";
import BaseLayout from "layouts/sections/components/BaseLayout";

function Dashboard() {
  const navigate = useNavigate();
  const [termoBusca, setTermoBusca] = useState("");
  
  const getAnuncios = () => {
    navigate("/pages/anuncios?termo=" + termoBusca);
  };
  const novoAnuncio = () => {
    navigate("/pages/painel");
  };

  return (
    <>
    <BaseLayout
      title=""
      breadcrumb={[{ label: "home", route: "/" }, { label: "Anúncios" }]}
    >

      <Grid container spacing={1} mt={3}>
        <Grid item xs={12} lg={7} justifyContent="center" mx="auto">
          <MKTypography
            variant="h1"
            color="primary"
            textAlign="center"
            mt={-2}
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("md")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            <i>zerados.com.br</i>
          </MKTypography>
          <MKTypography
            variant="body1"
            color="text"
            textAlign="center"
            px={{ xs: 6, lg: 12 }}
            mt={1}
          >
            A plataforma do carro que mais cresce em Minas Gerais
          </MKTypography>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <MKInput
            variant="standard"
            label="Buscar anúncios"
            color="black"
            fullWidth
            sx={{
              borderRadius: 2,
              display: "flex",
              margin: 0,
              paddingTop: 2,
              paddingBottom: 1,
              paddingRight: 2,
              paddingLeft: 2,
              backgroundColor: `rgba(241,241,241,.5)` }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  fontSize: "12px",
                  left: "1rem",
                  right: "1rem",
                }
              }}
            value={termoBusca}
            onChange={(e) => setTermoBusca(e.target.value)}
          />
        </Grid>
      
        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} display="flex" justifyContent="center" alignItems="center">
          <MKButton             
            type="button" 
            variant="gradient" 
            color="info" 
            mt={2} 
            onClick={getAnuncios}
          >
            <Icon>search</Icon>&nbsp;Pesquisar
          </MKButton>
          
        </Grid>
      </Grid>

      <Grid  container spacing={3} mt={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" justifyContent="center" alignItems="center">
          <MKButton             
            type="button" 
            variant="gradient" 
            color="warning" 
            mt={2} 
            onClick={novoAnuncio}
          >
            <Icon>newspaper</Icon>&nbsp;Quero anunciar
          </MKButton>
        </Grid>
      </Grid>
              <Marcas />
      <Grid 
        sx={{
          borderRadius: 2,
          p: 1,
          mx: { xs: 1, lg: 0 },
          mt: 4,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          //backgroundColor: "rgba(16, 101, 194, .2)",
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Novidades />
        <Counters />
        <Information />
        <Testimonials />
        </Grid>
      </BaseLayout>
    </>
  );
}

export default Dashboard;
